// Login.tsx
import '../App.css';
import React, { useState } from "react";
import { auth } from './firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

interface LoginProps {
    initUser: (user: any) => void;
}

const Login: React.FC<LoginProps> = ({ initUser }) => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const navigate = useNavigate();
    const [isSignUp, setIsSignUp] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [error, setError] = useState<string>("");

    const toggleForm = () => {
        setIsSignUp(!isSignUp);
        setError(""); // Clear error on toggle
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(""); // Reset error

        try {
            if (isSignUp) {
                // Sign-up user
                if (password !== confirmPassword) {
                    setError("Passwords do not match");
                    return;
                }
                await createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
                    alert("Sign up successful!");
                    const user = userCredential.user;
                    console.log("Signed in:", user);
                    navigate('/');
                });
            } else {
                // Log in user
                await signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
                    alert("Login successful!");
                    const user = userCredential.user;
                    initUser(user);
                    console.log("Logged in:", user);
                });
            }
            navigate('/');
        } catch (err: any) {
            setError(err.message);
        }
    };

    return (
        <div>
            <div>
                <h2>{isSignUp ? "Sign Up" : "Login"}</h2>
                <form onSubmit={handleSubmit}>
                    {isSignUp && (
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                placeholder="Your Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                    )}
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Your Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            placeholder="Your Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {isSignUp && (
                        <div>
                            <label htmlFor="confirm_password">Confirm Password</label>
                            <input
                                type="password"
                                id="confirm_password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                    )}
                    <button className="login-button" type="submit">{isSignUp ? "Sign Up" : "Login"}</button>
                </form>
                {error && <p className="error-message">{error}</p>}
                <p className="toggle-text">
                    {isSignUp
                        ? "Already have an account? "
                        : "Don’t have an account? "}
                    <span onClick={toggleForm}>
                        {isSignUp ? "Login" : "Sign Up"}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default Login;