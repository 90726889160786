import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import FlightCard from './components/FlightCard';
import 'chart.js/auto';
import FlightSearch from "./components/FlightSearch";
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import { onAuthStateChanged, signOut, User } from "firebase/auth";
import { auth } from './components/firebase';
import Dashboard from "./components/Dashboard";
import NotFound from './components/NotFound';
import FlightDetails from "./components/FlightDetails";
import {Dayjs} from "dayjs";
import {Flight, LocationOption} from "./types/types";

function App() {
    const apiUrl = process.env.REACT_APP_BACKEND_API_URL || "https://backend-912935791231.europe-west3.run.app";

    const [user, setUser] = useState<User | null>(null);
    const FLIGHTS_PER_PAGE = 50;
    const DEFAULT_RETURN_DAYS = 4;
    const DEFAULT_DEPARTURE_DAYS = 7;
    const [selectedDeparturePoint, setSelectedDeparturePoint] = useState<LocationOption | null>(null);
    const [selectedDepartureType, setSelectedDepartureType] = useState<string>('');
    const [selectedDestinationPoint, setSelectedDestinationPoint] = useState<LocationOption | null>(null);
    const [selectedDestinationType, setSelectedDestinationType] = useState<string>('');
    const [responseReceived, setResponseReceived] = useState<boolean>(false);
    const [selectedDepartureDate, setSelectedDepartureDate] = useState<Dayjs | null>(null);
    const [selectedReturnDate, setSelectedReturnDate] = useState<Dayjs | null>(null);
    const [tileData, setTileData] = useState<Flight[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [filterText, setFilterText] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [flightSubscriptions, setFlightSubscriptions] = useState<any[]>([]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("auth state changed: ", user)
            if (user) {
                const uid = user.uid;
                console.log("uid:", uid, "user:", user.email)
                setUser(user);
                fetchUserSubscriptions(user.email!);
            } else {
                signOut(auth).then(() => {
                    console.log("Signed out successfully")
                }).catch((error) => {
                    console.log("Error signing out", error.message)
                });
            }
            return () => unsubscribe();
        });
    }, [])

    const filteredItems = Array.isArray(tileData)
        ? tileData.filter(flight =>
            flight.cityTo.toLowerCase().includes(filterText.toLowerCase()) ||
            flight.countryTo.name.toLowerCase().includes(filterText.toLowerCase())
        ) : [];

    const totalPages = Math.min(Math.ceil(filteredItems.length / FLIGHTS_PER_PAGE), 10);
    const paginatedItems = filteredItems.slice(
        (currentPage - 1) * FLIGHTS_PER_PAGE,
        currentPage * FLIGHTS_PER_PAGE
    );

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(e.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleViewDetailsButtonClick = (item: Flight) => {
        console.log('Flight Selected:', item);
        localStorage.setItem("selectedFlight", JSON.stringify(item));
        window.open(`/flight_details`, '_blank');
    };

    const handleGetSearchResults = () => {
        if (selectedDeparturePoint && selectedDepartureDate) {
            console.log('Fetching flight deals for:', selectedDeparturePoint.code, selectedDepartureDate.toLocaleString())
            const dateString = selectedDepartureDate.format('YYYY-MM-DD');

            if (selectedReturnDate) {
                const returnDateString = selectedReturnDate.format('YYYY-MM-DD');
                fetchFlightDeals(selectedDepartureType, dateString, returnDateString, selectedDeparturePoint.code, selectedDestinationPoint?.code, true);
            } else {
                fetchFlightDeals(selectedDepartureType, dateString, null, selectedDeparturePoint.code, selectedDestinationPoint?.code, true);
            }
        }
    };

    const updateUser = (newValue: User | null) => {
        setUser(newValue);
    };

    function fetchUserSubscriptions(userEmail: string) {
        console.log('Fetching user subscriptions for user', user)
        if (userEmail) {
            fetch(`${apiUrl}/api/subscription?user_email=${userEmail}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch flight subscriptions');
                    }
                    return response.json();
                }).then(data => {
                    console.log('Flight subscriptions found:', data);
                    setFlightSubscriptions(data);
                }
            ).catch((error) => {
                console.error('Error fetching flight subscriptions:', error);
            });
        }
    }

    function fetchFlightDeals(departureType: string, date: string, returnDate: string | null = null, departureCode: string | null = null, destinationCode: string | null = null, exactMatch: boolean = false) {
        const returnDateParam = returnDate ? `&return_date=${returnDate}` : '';
        let url;
        if (exactMatch) {
            url = `${apiUrl}/api/price-drops?departure_type=${departureType}&departure_code=${departureCode}&departure_date=${date}${returnDateParam}&exact_match=true`;
        } else {
            url = `${apiUrl}/api/price-drops?departure_type=${departureType}&departure_code=${departureCode}&departure_date=${date}${returnDateParam}&exact_match=false`;
        }

        if (selectedDestinationType != null && destinationCode != null) {
            url = url + `&destination_type=${selectedDestinationType}&destination_code=${destinationCode}`
        }

        fetch(url)
            .then((response) => {
                setResponseReceived(true);

                if (!response.ok) {
                    if (response.status === 404) {
                        throw new Error("No flights deals found for " + departureCode + " on: " + date);
                    }
                    throw new Error("An unexpected error occurred.");
                }
                return response.json();
            })
            .then(data => {
                    setTileData(data)
                    setError(null);
                }
            ).catch(error => {
            console.error('Error getting flight results', error);
            setTileData([])
            setError(error.message);
        })
    }

    useEffect(() => {
        fetch('https://get.geojs.io/v1/ip/geo.json')
            .then(response => response.json())
            .then(data => {
                console.log('User location found:', data.country, data.country_code)
                data.code = data.country_code;
                data.name = data.country;
                setSelectedDeparturePoint(data);
                const today = new Date();
                const defaultDepartureDate = new Date(today);
                defaultDepartureDate.setDate(today.getDate() + DEFAULT_DEPARTURE_DAYS);
                const dateString = defaultDepartureDate.toISOString().split('T')[0];

                const defaultReturnDate = new Date(defaultDepartureDate);
                defaultReturnDate.setDate(defaultReturnDate.getDate() + DEFAULT_RETURN_DAYS);
                const returnDateString = defaultReturnDate.toISOString().split('T')[0];
                fetchFlightDeals("Country", dateString, returnDateString, data.country_code)
            })
            .catch(error => {
                console.error('Error fetching the location:', error);
            })
    }, [])

    const handleDepartureChange = (departure: LocationOption | null) => {
        console.log("Selected Departure: ", departure);
        if (departure != null) {
            setSelectedDeparturePoint(departure);
            setSelectedDepartureType(departure.type);
        }
    };

    const handleDestinationChange = (destination: LocationOption | null) => {
        if (destination != null) {
            console.log("Setting Destination: ", destination);
            setSelectedDestinationType(destination.type);
            setSelectedDestinationPoint(destination);
        }
    };

    const handleDepartureFlightDateChange = (date: Dayjs | null) => {
        if (date != null) {
            console.log("Selected Date: ", date);
            setSelectedDepartureDate(date);
        }
    };

    const handleReturnFlightDateChange = (date: Dayjs | null) => {
        if (date != null) {
            console.log("Selected Date: ", date);
            setSelectedReturnDate(date);
        }
    };

    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <h1>Latest Flight Deals</h1>
                </header>
                <nav className="navbar">
                    <div className="navbar-links">
                        <Link to="/" className="link">Home</Link>
                        <Link to="/dashboard" className="link">Dashboard</Link>
                        {user ? <Link to="/" className="link" onClick={() => updateUser(null)}>Logout</Link> :
                            <Link to="/login" className="link">Login</Link>}
                    </div>
                </nav>

                <section>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/flight_details" element={<FlightDetails />} />
                        <Route path="/login" element={<Login initUser={updateUser} />} />
                        <Route path="/dashboard" element={<Dashboard user={user} flightSubscriptions={flightSubscriptions} />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </section>
                <div>
                    <br /><br /><br />
                    <FlightSearch
                        selectedDepartureDate={selectedDepartureDate}
                        handleDepartureDateChange={handleDepartureFlightDateChange}
                        selectedReturnDate={selectedReturnDate}
                        handleReturnDateChange={handleReturnFlightDateChange}
                        handleDepartureChange={handleDepartureChange}
                        handleDestinationChange={handleDestinationChange}
                        handleGetSearchResults={handleGetSearchResults}
                    />
                    <br />
                </div>
                <br /><br />

                {responseReceived ? (
                    <div className="pagination">
                        <h3>Showing Deals for: {selectedDeparturePoint?.name}</h3>
                        <input
                            type="text"
                            placeholder="Search in results..."
                            value={filterText}
                            onChange={handleFilterChange}
                        />
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                onClick={() => handlePageChange(index + 1)}
                                className={index + 1 === currentPage ? 'active' : ''}
                            >
                                {index + 1}
                            </button>
                        ))}

                    </div>

                ) : <h3>Loading Flight Deals...</h3>}

                <div className="grid-container">
                    {paginatedItems.map((item) => (

                        <FlightCard
                            item={item}
                            onButtonClick={handleViewDetailsButtonClick}
                        />

                    ))}
                </div>

                <br /><br /><br />
                {error && (
                    <div>
                        <p>{error}</p>
                    </div>
                )}
            </div>
        </Router>
    );
}

export default App;