import React, { useState } from "react";
import {
    Button,
    Box,
    TextField,
    Autocomplete,
    TextFieldProps
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import axios from "axios";
import { LocationOption } from "../types/types";

interface CountryCitySelectorProps {
    selectedDepartureDate: Dayjs | null;
    handleDepartureDateChange: (date: Dayjs | null) => void;
    selectedReturnDate: Dayjs | null;
    handleReturnDateChange: (date: Dayjs | null) => void;
    handleDepartureChange: (departure: LocationOption | null) => void;
    handleDestinationChange: (destination: LocationOption | null) => void;
    handleGetSearchResults: () => void;
}

const FlightSearch: React.FC<CountryCitySelectorProps> = ({
    selectedDepartureDate,
    handleDepartureDateChange,
    selectedReturnDate,
    handleReturnDateChange,
    handleDepartureChange,
    handleDestinationChange,
    handleGetSearchResults
}) => {
    const apiUrl = process.env.REACT_APP_BACKEND_API_URL || "https://backend-912935791231.europe-west3.run.app";

    const [departureOptions, setDepartureOptions] = useState<LocationOption[]>([]);
    const [departureInputValue, setDepartureInputValue] = useState<string>("");

    const [destinationOptions, setDestinationOptions] = useState<LocationOption[]>([]);
    const [destinationInputValue, setDestinationInputValue] = useState<string>("");

    const fetchOptions = async (query: string) => {
        try {
            // TODO change to fetch from DB
            const response = await axios.get(`${apiUrl}/api/locations`, { params: { location_query: query } });
            setDestinationOptions(response.data.locations); // Assume server returns a list of options
        } catch (error) {
            console.error("Error fetching autocomplete options:", error);
        }
    };

    const fetchDepartureOptions = async (query: string) => {
        try {
            // TODO change to fetch from DB
            const response = await axios.get(`${apiUrl}/api/locations`, { params: { location_query: query } });
            setDepartureOptions(response.data.locations); // Assume server returns a list of options
        } catch (error) {
            console.error("Error fetching departure autocomplete options:", error);
        }
    };

    const handleOptionChange = (event: React.SyntheticEvent, option: LocationOption | null) => {
        console.log(option);
        setDepartureInputValue(option?.name || ""); // Update input display with the name or similar field
        if (handleDepartureChange) handleDepartureChange(option); // Notify parent about the selection
    };

    const handleDestinationOptionChange = (event: React.SyntheticEvent, option: LocationOption | null) => {
        setDestinationInputValue(option?.name || ""); // Update input display with the name or similar field
        if (handleDestinationChange) handleDestinationChange(option); // Notify parent about the selection
    };

    const handleDestinationInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        setDestinationInputValue(value);
        if (value) fetchOptions(value); // Fetch filtered options as the user types
    };

    const handleDepartureInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        console.log(value);
        setDepartureInputValue(value);
        if (value) fetchDepartureOptions(value);
    };

    return (
        <div style={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: 'white', padding: '20px 0', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                gap: 2, 
                alignItems: 'center',
                width: '100%',
                padding: '0 20px'
            }}>
                <Autocomplete
                    sx={{ flex: 1 }}
                    options={departureOptions}
                    getOptionLabel={(option) => `${option.name} (${option.type})`}
                    inputValue={departureInputValue}
                    onInputChange={handleDepartureInputChange}
                    onChange={handleOptionChange}
                    renderInput={(params: TextFieldProps) => <TextField {...params} label="Departure" />}
                />
                
                <Autocomplete
                    sx={{ flex: 1 }}
                    options={destinationOptions}
                    getOptionLabel={(option) => `${option.name} (${option.type})`}
                    inputValue={destinationInputValue}
                    onInputChange={handleDestinationInputChange}
                    onChange={handleDestinationOptionChange}
                    renderInput={(params: TextFieldProps) => <TextField {...params} label="Destination" />}
                />
                
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Departure Date"
                        value={selectedDepartureDate}
                        onChange={(newDate: Dayjs | null) => {
                            handleDepartureDateChange(newDate);
                        }}
                        sx={{ flex: 1 }}
                    />
                </LocalizationProvider>
                
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Return Date"
                        value={selectedReturnDate}
                        onChange={(newDate: Dayjs | null) => {
                            handleReturnDateChange(newDate);
                        }}
                        sx={{ flex: 1 }}
                    />
                </LocalizationProvider>
                
                <Button 
                    disabled={!selectedDepartureDate || !departureInputValue}
                    variant="contained"
                    onClick={handleGetSearchResults}
                    sx={{ height: '56px', minWidth: '120px' }}
                >
                    Search Flights
                </Button>
            </Box>
        </div>
    );
};

export default FlightSearch;