import React, {useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {
    Container,
    Grid2,
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    Stack,
} from "@mui/material";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import dayjs, {Dayjs} from "dayjs";
import {Flight} from "../types/types";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const FlightDetails: React.FC = () => {
        const [flightData, setFlightData] = useState<Flight | null>(null);
        const [isLoading, setIsLoading] = useState<boolean>(true);
        const apiUrl = process.env.REACT_APP_BACKEND_API_URL || "http://localhost:5000";

        const fetchStoredFlightData = () => {
            console.log("fetchStoredFlightData");
            const storedItem = localStorage.getItem("selectedFlight");
            console.log("storedItem:", storedItem);
            if (storedItem) {
                setFlightData(JSON.parse(storedItem));
                setIsLoading(false);
            } else {
                console.log("No priceChartData found in local storage");
            }
        };

        useEffect(() => {
            fetchStoredFlightData();
        }, []);

        const priceChartData = flightData && flightData?.price.length > 0
            ? {
                labels: flightData?.price.map(item => dayjs(item.collectedAt).format('DD MMM')),
                datasets: [
                    {
                        label: 'Price (€)',
                        data: flightData?.price.map(item => item.price),
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderWidth: 2,
                        fill: true,
                    },
                ],
            } : null;

        const priceChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    type: 'category' as const, // Ensure the type is correctly se,
                    title: {
                        display: true,
                        text: 'Date',
                    },
                },
                y: {
                    beginAtZero: true,
                },
            },
        };

        const handleRedirect = () => {
            console.log('Redirecting to Kiwi.com');
            console.log("Selected Flight Deep Link: ", flightData?.deep_link);
            const kiwiLink = flightData?.deep_link;
            if (kiwiLink) {
                window.open(kiwiLink, '_blank');
            }
        };

        const navigate = useNavigate();
        const handleCloseFlightDetails = () => {
            console.log('Closing Flight Details');
            navigate('/');
        };

        const handleSubscribeFlight = () => {
            console.log('Subscribing to flight:', flightData);
            let user = auth.currentUser;
            if (!user) {
                console.log("User not logged in, please login to subscribe to flight");
                alert("Please login to subscribe to flight");
                return;
            }
            const flightDataDao = {
                flight_id: flightData?.id,
                departure_date: flightData?.local_departure,
                fly_from: flightData?.flyFrom,
                fly_to: flightData?.flyTo,
                price: flightData?.current_price,
                user_id: user.uid,
                user_email: user.email,
                collection_timestamp: new Date().toISOString()
            };

            console.log("Flight Data to subscribe: ", flightDataDao);
            fetch(`${apiUrl}/api/subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(flightDataDao),
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Subscription successful:', data);
                })
                .catch(error => {
                    console.error('Error subscribing to flight:', error);
                });
        };


// Show a loading spinner while fetching priceChartData
        if (isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <Container
                maxWidth="md"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "top",
                    height: "100vh",
                    padding: "20px",
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Flight Details
                </Typography>

                {/* Flight Details Cards */}
                <Grid2 container
                       sx={{
                           justifyContent: "center",
                           alignItems: "center",
                           padding: "20px",
                           backgroundColor: "#f5f5f5",
                       }}
                       spacing={8} justifyContent="center">
                    {/* Outbound Flight Details */}
                    <Grid2> {/*item xs={12} sm={6}>*/}
                        <Card
                            sx={{
                                boxShadow: 3,
                                borderRadius: "10px",
                                backgroundColor: "#ffffff",
                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Outbound Flight
                                </Typography>
                                <Typography><strong>Airline:</strong> {flightData?.airlines[0]}</Typography>
                                <Typography><strong>Departure:</strong> {flightData?.flyFrom}, {flightData?.cityFrom}, {flightData?.countryFrom.name}
                                </Typography>
                                <Typography><strong>Departure Time:</strong> {flightData?.local_departure}</Typography>

                                <Typography><strong>Arrival:</strong> {flightData?.flyTo}, {flightData?.cityTo}, {flightData?.countryTo.name}
                                </Typography>
                                <Typography><strong>Arrival Time:</strong> {flightData?.local_arrival}</Typography>
                                {/*<Typography>Duration: {outboundFlight.duration}</Typography>*/}
                            </CardContent>
                        </Card>
                    </Grid2>

                    {/* Return Flight Details */}
                    {/*<Grid2 item xs={12} sm={6}>*/}
                    <Grid2>
                        {flightData?.route && flightData?.route.length > 1 && flightData?.route[1] && (
                            <Card
                                sx={{
                                    boxShadow: 3,
                                    borderRadius: "10px",
                                    backgroundColor: "#ffffff",
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Return Flight
                                    </Typography>
                                    <Typography><strong>Airline:</strong> {flightData?.route[1].airline}</Typography>
                                    <Typography><strong>Departure:</strong> {flightData?.route[1].flyFrom}, {flightData?.route[1].cityFrom}, {flightData?.countryTo.name}
                                    </Typography>
                                    <Typography><strong>Departure Time:</strong> {flightData?.route[1].local_departure}
                                    </Typography>

                                    <Typography><strong>Arrival:</strong> {flightData?.route[1].flyTo}, {flightData?.route[1].cityTo}
                                    </Typography>
                                    <Typography><strong>Arrival Time:</strong> {flightData?.route[1].local_arrival}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                    </Grid2>

                </Grid2>

                {/* Chart Section */}
                {priceChartData && (
                    <Box
                        sx={{
                            width: "70%",
                            height: "400px",
                            marginTop: "40px",
                        }}
                    >
                        <Line data={priceChartData} options={priceChartOptions}/>
                    </Box>
                )}

                {/* Buttons Section */}
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        marginTop: "20px",
                        justifyContent: "center",
                    }}
                >
                    <Button onClick={handleCloseFlightDetails} variant="outlined" color="secondary">
                        Back to Search
                    </Button>
                    <Button onClick={handleSubscribeFlight} variant="contained" color="primary">
                        Monitor Flight
                    </Button>
                    <Button onClick={handleRedirect} variant="contained" color="success">
                        Buy It
                    </Button>
                </Stack>
            </Container>
        );
    }
;

export default FlightDetails;